/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";

body {
    height: 100vh;
}

.padding-40 {
    padding: 40px !important;
}

.mat-datepicker-content .mat-calendar {
    width: 100% !important;
    height: 100% !important;
}

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
    background-color: #008cb7 !important;
}

.mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled {
    background-color: #0000001f !important;
}

.mat-progress-bar-fill::after {
    background-color: #008cb7;
}

.mat-progress-bar-buffer {
    background: #E4E8EB;
}

.mat-progress-bar {
    border-radius: 2px;
}
